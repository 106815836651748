import axios from 'axios';
import mainApi from '../config/api';

// Use the main API configuration
const { baseURL: API_URL, headers } = mainApi.defaults;
const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === 'true';

// Create a maintenance mode API instance that only allows certain endpoints
const maintenanceApi = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 10000,
});

// Only allow specific endpoints in maintenance mode
maintenanceApi.interceptors.request.use(
  (config) => {
    const allowedEndpoints = [
      '/api/content/contact',  // Allow fetching contact info
      '/api/email/send-contact' // Allow sending contact form
    ];

    const endpoint = config.url.replace(API_URL, '');
    if (!allowedEndpoints.some(allowed => endpoint.includes(allowed))) {
      return Promise.reject(new Error('Site is in maintenance mode'));
    }
    return config;
  },
  (error) => Promise.reject(error)
);

const api = isMaintenanceMode ? maintenanceApi : mainApi;

// Add token to requests if it exists
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.error('Request error:', error);
    return Promise.reject(error);
  }
);

// Add response interceptor for error handling
api.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error('API Error:', {
      url: error.config?.url,
      method: error.config?.method,
      status: error.response?.status,
      data: error.response?.data,
      message: error.message
    });

    if (!error.response) {
      return Promise.reject({
        message: 'Network error - please check your connection',
        originalError: error
      });
    }

    return Promise.reject(error.response.data);
  }
);

// Auth API
export const auth = {
  login: (credentials) => axios.post('/api/auth/login', credentials),
  register: (userData) => axios.post('/api/auth/register', userData),
  getMe: () => axios.get('/api/auth/me'),
  updatePassword: (passwords) => axios.post('/api/auth/update-password', passwords)
};

// Contact form submission
export const submitContactForm = async (formData) => {
  try {
    const response = await api.post('/api/contact', formData);
    return response.data;
  } catch (error) {
    throw error.response?.data || {
      message: 'Failed to submit contact form',
      error: error.message
    };
  }
};

// Content management
const contentService = {
  getContent: async (section) => {
    try {
      const response = await api.get(`/api/content/${section}`);

      return response.data;
    } catch (error) {
      console.error(`Error fetching ${section} content:`, error);
      throw error;
    }
  },
  getAllContent: () => api.get('/api/content'),
  updateContent: async (section, data) => {
    try {
      const response = await api.put(`/api/content/${section}`, data);
      return response.data;
    } catch (error) {
      throw error.response?.data || error.message;
    }
  },
  createContent: (data) => api.post('/api/content', data),
  deleteContent: (section) => api.delete(`/api/content/${section}`),
  getContentHistory: (section) => api.get(`/api/content/${section}/history`)
};

export const content = contentService;

// Media API
export const media = {
  uploadMedia: async (formData) => {
    try {
      const response = await api.post('/api/media/upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      return response.data;
    } catch (error) {
      throw error.response?.data || error.message;
    }
  },
  getAllMedia: (usage) => api.get('/api/media', { params: { usage } }),
  deleteMedia: (id) => api.delete(`/api/media/${id}`),
  updateMedia: (id, data) => api.put(`/api/media/${id}`, data)
};

export default api;
