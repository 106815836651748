import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { content as contentService } from '../../services/api';
import {
  Box,
  Container,
  Typography,
  Paper,
  Tabs,
  Tab,
  Button,
  TextField,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  CircularProgress,
  Alert,
  FormControlLabel,
  Switch
} from '@mui/material';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
} from '@mui/icons-material';
import EmailConfig from './EmailConfig';
import SeoConfig from './SeoConfig';

const AdminPanel = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [siteContent, setSiteContent] = useState({
    home: {},
    timeline: {
      clients: []
    },
    skills: [],
    'case-studies': {},
    testimonials: {},
    contact: {},
    footer: {},
    about: {},
    emailConfig: {},
    seo: {}
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [saveStatus, setSaveStatus] = useState({ show: false, success: false, message: '' });

  useEffect(() => {
    fetchAllContent();
  }, []);

  const fetchAllContent = async () => {
    try {
      const token = localStorage.getItem('token');
      
      if (!token) {
        setError('Not authenticated. Please log in again.');
        navigate('/admin');
        return;
      }

      const types = ['home', 'timeline', 'skills', 'case-studies', 'testimonials', 'contact', 'footer', 'about', 'emailConfig', 'seo'];
      
      const responses = await Promise.all(
        types.map(async type => {
          try {
            const response = await contentService.getContent(type, {
              headers: { 
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
              }
            });
            return { type, data: response };
          } catch (error) {
            console.error(`Error fetching ${type}:`, error);
            return { type, data: null };
          }
        })
      );

      const newContent = {};
      responses.forEach(({ type, data }) => {
        if (!data) {
          newContent[type] = {};
          return;
        }
        
        if (type === 'timeline' || type === 'skills') {
          newContent[type] = Array.isArray(data.content) ? data.content : [];
        } else {
          newContent[type] = data.content || {};
        }
      });

      setSiteContent(newContent);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching content:', error);
      if (error.response?.status === 401) {
        setError('Session expired. Please log in again.');
        localStorage.removeItem('token');
        navigate('/admin');
      } else {
        setError(`Failed to load content: ${error.message}`);
      }
    }
  };

  const handleSave = async (type, data) => {
    try {
      setSaveStatus({ show: true, success: false, message: 'Saving...' });
      const token = localStorage.getItem('token');
      
      if (!token) {
        setSaveStatus({ show: true, success: false, message: 'Not authenticated. Please log in again.' });
        navigate('/admin');
        return;
      }

      // All sections should be wrapped in a content field
      const payload = {
        type,
        content: data,
        status: 'published'
      };
      
      await contentService.updateContent(type, payload, {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      setSaveStatus({ show: true, success: true, message: 'Content saved successfully!' });
      fetchAllContent();
    } catch (error) {
      console.error('Error saving content:', error);
      if (error.response?.status === 401) {
        setSaveStatus({ show: true, success: false, message: 'Session expired. Please log in again.' });
        localStorage.removeItem('token');
        navigate('/admin');
      } else {
        setSaveStatus({ show: true, success: false, message: `Failed to save content: ${error.message}` });
      }
    }
  };

  const handleContentChange = (type, field, value) => {
    setSiteContent(prev => {
      if (type === 'timeline' || type === 'skills') {
        return {
          ...prev,
          [type]: Array.isArray(value) ? value : []
        };
      } else {
        return {
          ...prev,
          [type]: {
            ...prev[type],
            [field]: value
          }
        };
      }
    });
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/admin');
  };

  const handleAddMedia = () => {
    const newMedia = { title: '', url: '' };
    setSiteContent(prev => ({
      ...prev,
      about: {
        ...prev.about,
        media: [...(prev.about?.media || []), newMedia]
      }
    }));
  };

  const renderHomeSection = () => (
    <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
      <Typography variant="h6" gutterBottom>Home Page Content</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Name"
            value={siteContent.home?.name || ''}
            onChange={(e) => handleContentChange('home', 'name', e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Title"
            value={siteContent.home?.title || ''}
            onChange={(e) => handleContentChange('home', 'title', e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Tagline"
            value={siteContent.home?.tagline || ''}
            onChange={(e) => handleContentChange('home', 'tagline', e.target.value)}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>Stats</Typography>
          <List>
            {(siteContent.home?.stats || []).map((stat, index) => (
              <ListItem key={index}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={5}>
                    <TextField
                      fullWidth
                      label="Label"
                      value={stat.label || ''}
                      onChange={(e) => {
                        const newStats = [...(siteContent.home?.stats || [])];
                        newStats[index] = { ...stat, label: e.target.value };
                        handleContentChange('home', 'stats', newStats);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <TextField
                      fullWidth
                      label="Value"
                      value={stat.value || ''}
                      onChange={(e) => {
                        const newStats = [...(siteContent.home?.stats || [])];
                        newStats[index] = { ...stat, value: e.target.value };
                        handleContentChange('home', 'stats', newStats);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <IconButton
                      edge="end"
                      onClick={() => {
                        const newStats = [...(siteContent.home?.stats || [])];
                        newStats.splice(index, 1);
                        handleContentChange('home', 'stats', newStats);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </ListItem>
            ))}
          </List>
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() => {
              const newStat = { label: '', value: '' };
              handleContentChange('home', 'stats', [...(siteContent.home?.stats || []), newStat]);
            }}
            sx={{ mt: 1 }}
          >
            Add Stat
          </Button>
        </Grid>
      </Grid>
      <Button
        variant="contained"
        color="primary"
        startIcon={<SaveIcon />}
        onClick={() => handleSave('home', siteContent.home)}
        sx={{ mt: 2 }}
      >
        Save Home Content
      </Button>
    </Paper>
  );

  const renderAboutSection = () => (
    <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
      <Typography variant="h6" gutterBottom>About Section</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Title"
            value={siteContent.about?.title || ''}
            onChange={(e) => handleContentChange('about', 'title', e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            rows={3}
            label="Description"
            value={siteContent.about?.description || ''}
            onChange={(e) => handleContentChange('about', 'description', e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            rows={4}
            label="Mission"
            value={siteContent.about?.mission || ''}
            onChange={(e) => handleContentChange('about', 'mission', e.target.value)}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>Media</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Profile Image URL"
                value={siteContent.about?.media?.profileImage || ''}
                onChange={(e) => handleContentChange('about', 'media', {
                  ...siteContent.about?.media,
                  profileImage: e.target.value,
                  gallery: siteContent.about?.media?.gallery || []
                })}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Button
        variant="contained"
        color="primary"
        startIcon={<SaveIcon />}
        onClick={() => handleSave('about', siteContent.about)}
        sx={{ mt: 2 }}
      >
        Save About Content
      </Button>
    </Paper>
  );

  const renderHeroSection = () => (
    <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
      <Typography variant="h6" gutterBottom>Hero Section</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Tagline"
            value={siteContent.home?.tagline || ''}
            onChange={(e) => handleContentChange('home', 'tagline', e.target.value)}
          />
        </Grid>
      </Grid>
    </Paper>
  );

  const renderAchievementsSection = () => (
    <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
      <Typography variant="h6" gutterBottom>Achievements Section</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>Stats</Typography>
          {!siteContent.home?.stats && handleContentChange('home', 'stats', [])}
          {(siteContent.home?.stats || []).map((stat, index) => (
            <Grid container spacing={2} key={index} sx={{ mb: 2 }}>
              <Grid item xs={12} sm={5}>
                <TextField
                  fullWidth
                  label="Label"
                  value={stat.label || ''}
                  onChange={(e) => {
                    const newStats = [...(siteContent.home?.stats || [])];
                    newStats[index] = { ...stat, label: e.target.value };
                    handleContentChange('home', 'stats', newStats);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <TextField
                  fullWidth
                  label="Value"
                  value={stat.value || ''}
                  onChange={(e) => {
                    const newStats = [...(siteContent.home?.stats || [])];
                    newStats[index] = { ...stat, value: e.target.value };
                    handleContentChange('home', 'stats', newStats);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <IconButton
                  onClick={() => {
                    const newStats = [...(siteContent.home?.stats || [])];
                    newStats.splice(index, 1);
                    handleContentChange('home', 'stats', newStats);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <Button
            startIcon={<AddIcon />}
            onClick={() => {
              const newStats = [...(siteContent.home?.stats || []), { label: '', value: '' }];
              handleContentChange('home', 'stats', newStats);
            }}
            variant="outlined"
            sx={{ mt: 2 }}
          >
            Add Stat
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );

  const renderTimelineSection = () => {
    const addTimelineItem = () => {
      const newItem = {
        title: '',
        company: '',
        startDate: '',
        endDate: '',
        positionType: 'Permanent',
        description: '',
        achievements: [],
        technologies: []
      };
      handleContentChange('timeline', '', [...(siteContent.timeline.clients || []), newItem]);
    };

    const updateTimelineItem = (index, field, value) => {
      const newTimeline = [...(siteContent.timeline.clients || [])];
      newTimeline[index] = {
        ...newTimeline[index],
        [field]: value
      };
      handleContentChange('timeline', 'clients', newTimeline);
    };

    const removeTimelineItem = (index) => {
      const newTimeline = [...(siteContent.timeline.clients || [])];
      newTimeline.splice(index, 1);
      handleContentChange('timeline', 'clients', newTimeline);
    };

    const addArrayItem = (index, field) => {
      const newTimeline = [...(siteContent.timeline.clients || [])];
      newTimeline[index] = {
        ...newTimeline[index],
        [field]: [...(newTimeline[index][field] || []), '']
      };
      handleContentChange('timeline', 'clients', newTimeline);
    };

    const updateArrayItem = (timelineIndex, field, arrayIndex, value) => {
      const newTimeline = [...(siteContent.timeline.clients || [])];
      const newArray = [...(newTimeline[timelineIndex][field] || [])];
      newArray[arrayIndex] = value;
      newTimeline[timelineIndex] = {
        ...newTimeline[timelineIndex],
        [field]: newArray
      };
      handleContentChange('timeline', 'clients', newTimeline);
    };

    const removeArrayItem = (timelineIndex, field, arrayIndex) => {
      const newTimeline = [...(siteContent.timeline.clients || [])];
      const newArray = [...(newTimeline[timelineIndex][field] || [])];
      newArray.splice(arrayIndex, 1);
      newTimeline[timelineIndex] = {
        ...newTimeline[timelineIndex],
        [field]: newArray
      };
      handleContentChange('timeline', 'clients', newTimeline);
    };

    return (
      <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Typography variant="h6">Timeline Entries</Typography>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={addTimelineItem}
          >
            Add Entry
          </Button>
        </Box>

        {(siteContent.timeline.clients || []).map((item, index) => (
          <Paper key={index} elevation={2} sx={{ p: 3, mb: 3, position: 'relative' }}>
            <IconButton
              onClick={() => removeTimelineItem(index)}
              sx={{ position: 'absolute', top: 8, right: 8 }}
            >
              <DeleteIcon />
            </IconButton>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Job Title"
                  value={item.title || ''}
                  onChange={(e) => updateTimelineItem(index, 'title', e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Company"
                  value={item.company || ''}
                  onChange={(e) => updateTimelineItem(index, 'company', e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Start Date (e.g., Jan 2025)"
                  value={item.startDate || ''}
                  onChange={(e) => updateTimelineItem(index, 'startDate', e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="End Date (or 'Present')"
                  value={item.endDate || ''}
                  onChange={(e) => updateTimelineItem(index, 'endDate', e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  select
                  label="Position Type"
                  value={item.positionType || 'Permanent'}
                  onChange={(e) => updateTimelineItem(index, 'positionType', e.target.value)}
                  SelectProps={{
                    native: true,
                  }}
                >
                  {['Permanent', 'Contract', 'Freelance'].map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  label="Description"
                  value={item.description || ''}
                  onChange={(e) => updateTimelineItem(index, 'description', e.target.value)}
                />
              </Grid>

              {/* Achievements Section */}
              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Key Achievements
                </Typography>
                <List>
                  {(item.achievements || []).map((achievement, achievementIndex) => (
                    <ListItem key={achievementIndex}>
                      <TextField
                        fullWidth
                        value={achievement}
                        onChange={(e) => updateArrayItem(index, 'achievements', achievementIndex, e.target.value)}
                      />
                      <IconButton
                        onClick={() => removeArrayItem(index, 'achievements', achievementIndex)}
                        edge="end"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItem>
                  ))}
                </List>
                <Button
                  startIcon={<AddIcon />}
                  onClick={() => addArrayItem(index, 'achievements')}
                >
                  Add Achievement
                </Button>
              </Grid>

              {/* Technologies Section */}
              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Technologies & Tools
                </Typography>
                <List>
                  {(item.technologies || []).map((tech, techIndex) => (
                    <ListItem key={techIndex}>
                      <TextField
                        fullWidth
                        value={tech}
                        onChange={(e) => updateArrayItem(index, 'technologies', techIndex, e.target.value)}
                      />
                      <IconButton
                        onClick={() => removeArrayItem(index, 'technologies', techIndex)}
                        edge="end"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItem>
                  ))}
                </List>
                <Button
                  startIcon={<AddIcon />}
                  onClick={() => addArrayItem(index, 'technologies')}
                >
                  Add Technology
                </Button>
              </Grid>
            </Grid>
          </Paper>
        ))}

        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            onClick={() => handleSave('timeline', siteContent.timeline)}
          >
            Save Timeline
          </Button>
        </Box>
      </Paper>
    );
  };

  const renderSkillsSection = () => (
    <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
      <Typography variant="h6" gutterBottom>Skills & Expertise</Typography>
      <List>
        {(siteContent.skills || []).map((skill, index) => (
          <ListItem key={index}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Category"
                  value={skill.category || ''}
                  onChange={(e) => {
                    const newSkills = [...(siteContent.skills || [])];
                    newSkills[index] = {
                      ...newSkills[index],
                      category: e.target.value
                    };
                    handleContentChange('skills', null, newSkills);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={7}>
                <TextField
                  fullWidth
                  label="Description"
                  value={skill.description || ''}
                  onChange={(e) => {
                    const newSkills = [...(siteContent.skills || [])];
                    newSkills[index] = {
                      ...newSkills[index],
                      description: e.target.value
                    };
                    handleContentChange('skills', null, newSkills);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <IconButton
                  edge="end"
                  onClick={() => {
                    const newSkills = [...(siteContent.skills || [])];
                    newSkills.splice(index, 1);
                    handleContentChange('skills', null, newSkills);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          </ListItem>
        ))}
      </List>
      <Button
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={() => {
          const newSkills = [...(siteContent.skills || []), { category: '', description: '' }];
          handleContentChange('skills', null, newSkills);
        }}
        sx={{ mt: 1, mb: 2 }}
      >
        Add Skill
      </Button>
      <Box sx={{ mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<SaveIcon />}
          onClick={() => handleSave('skills', siteContent.skills)}
        >
          Save Skills
        </Button>
      </Box>
    </Paper>
  );

  const renderCaseStudiesSection = () => {
    const addCaseStudy = () => {
      const newStudy = {
        title: '',
        client: '',
        industry: '',
        year: '',
        featured: false,
        image: null,
        description: '',
        challenge: '',
        solution: '',
        results: [],
        testimonial: {
          quote: '',
          author: '',
          position: ''
        }
      };
      handleContentChange('case-studies', 'studies', [...(siteContent['case-studies']?.studies || []), newStudy]);
    };

    const updateCaseStudy = (index, field, value) => {
      const newStudies = [...(siteContent['case-studies']?.studies || [])];
      newStudies[index] = {
        ...newStudies[index],
        [field]: value
      };
      handleContentChange('case-studies', 'studies', newStudies);
    };

    const removeCaseStudy = (index) => {
      const newStudies = [...(siteContent['case-studies']?.studies || [])];
      newStudies.splice(index, 1);
      handleContentChange('case-studies', 'studies', newStudies);
    };

    const addResult = (studyIndex) => {
      const newStudies = [...(siteContent['case-studies']?.studies || [])];
      newStudies[studyIndex] = {
        ...newStudies[studyIndex],
        results: [...(newStudies[studyIndex].results || []), '']
      };
      handleContentChange('case-studies', 'studies', newStudies);
    };

    const updateResult = (studyIndex, resultIndex, value) => {
      const newStudies = [...(siteContent['case-studies']?.studies || [])];
      const newResults = [...(newStudies[studyIndex].results || [])];
      newResults[resultIndex] = value;
      newStudies[studyIndex] = {
        ...newStudies[studyIndex],
        results: newResults
      };
      handleContentChange('case-studies', 'studies', newStudies);
    };

    const removeResult = (studyIndex, resultIndex) => {
      const newStudies = [...(siteContent['case-studies']?.studies || [])];
      const newResults = [...(newStudies[studyIndex].results || [])];
      newResults.splice(resultIndex, 1);
      newStudies[studyIndex] = {
        ...newStudies[studyIndex],
        results: newResults
      };
      handleContentChange('case-studies', 'studies', newStudies);
    };

    return (
      <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Typography variant="h6">Case Studies</Typography>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={addCaseStudy}
          >
            Add Case Study
          </Button>
        </Box>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Section Title"
              value={siteContent['case-studies']?.title || ''}
              onChange={(e) => handleContentChange('case-studies', 'title', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Section Description"
              value={siteContent['case-studies']?.description || ''}
              onChange={(e) => handleContentChange('case-studies', 'description', e.target.value)}
            />
          </Grid>
        </Grid>

        {(siteContent['case-studies']?.studies || []).map((study, studyIndex) => (
          <Paper key={studyIndex} elevation={2} sx={{ p: 3, mt: 3, position: 'relative' }}>
            <IconButton
              onClick={() => removeCaseStudy(studyIndex)}
              sx={{ position: 'absolute', top: 8, right: 8 }}
            >
              <DeleteIcon />
            </IconButton>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Title"
                  value={study.title || ''}
                  onChange={(e) => updateCaseStudy(studyIndex, 'title', e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Client"
                  value={study.client || ''}
                  onChange={(e) => updateCaseStudy(studyIndex, 'client', e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Industry"
                  value={study.industry || ''}
                  onChange={(e) => updateCaseStudy(studyIndex, 'industry', e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Year"
                  value={study.year || ''}
                  onChange={(e) => updateCaseStudy(studyIndex, 'year', e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={study.featured || false}
                      onChange={(e) => updateCaseStudy(studyIndex, 'featured', e.target.checked)}
                    />
                  }
                  label="Featured on Home Page"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Image URL"
                  value={study.image || ''}
                  onChange={(e) => updateCaseStudy(studyIndex, 'image', e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={2}
                  label="Description"
                  value={study.description || ''}
                  onChange={(e) => updateCaseStudy(studyIndex, 'description', e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  label="Challenge"
                  value={study.challenge || ''}
                  onChange={(e) => updateCaseStudy(studyIndex, 'challenge', e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  label="Solution"
                  value={study.solution || ''}
                  onChange={(e) => updateCaseStudy(studyIndex, 'solution', e.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Results
                </Typography>
                <List>
                  {(study.results || []).map((result, resultIndex) => (
                    <ListItem key={resultIndex}>
                      <TextField
                        fullWidth
                        value={result}
                        onChange={(e) => updateResult(studyIndex, resultIndex, e.target.value)}
                      />
                      <IconButton
                        onClick={() => removeResult(studyIndex, resultIndex)}
                        edge="end"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItem>
                  ))}
                </List>
                <Button
                  startIcon={<AddIcon />}
                  onClick={() => addResult(studyIndex)}
                >
                  Add Result
                </Button>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Testimonial
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      multiline
                      rows={2}
                      label="Quote"
                      value={study.testimonial?.quote || ''}
                      onChange={(e) => updateCaseStudy(studyIndex, 'testimonial', {
                        ...study.testimonial,
                        quote: e.target.value
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Author"
                      value={study.testimonial?.author || ''}
                      onChange={(e) => updateCaseStudy(studyIndex, 'testimonial', {
                        ...study.testimonial,
                        author: e.target.value
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Position"
                      value={study.testimonial?.position || ''}
                      onChange={(e) => updateCaseStudy(studyIndex, 'testimonial', {
                        ...study.testimonial,
                        position: e.target.value
                      })}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        ))}

        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            onClick={() => handleSave('case-studies', siteContent['case-studies'])}
          >
            Save Case Studies
          </Button>
        </Box>
      </Paper>
    );
  };

  const renderTestimonialsSection = () => (
    <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
      <Typography variant="h6" gutterBottom>Testimonials</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Section Title"
            value={siteContent.testimonials?.title || ''}
            onChange={(e) => handleContentChange('testimonials', 'title', e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Section Description"
            value={siteContent.testimonials?.description || ''}
            onChange={(e) => handleContentChange('testimonials', 'description', e.target.value)}
          />
        </Grid>
      </Grid>

      <Typography variant="subtitle1" sx={{ mt: 3, mb: 2 }}>Testimonial Items</Typography>
      <List>
        {siteContent.testimonials?.testimonials?.map((testimonial, index) => (
          <ListItem key={index}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Name"
                  value={testimonial.name || ''}
                  onChange={(e) => {
                    const newTestimonials = [...(siteContent.testimonials?.testimonials || [])];
                    newTestimonials[index] = { ...testimonial, name: e.target.value };
                    handleContentChange('testimonials', 'testimonials', newTestimonials);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Position"
                  value={testimonial.position || ''}
                  onChange={(e) => {
                    const newTestimonials = [...(siteContent.testimonials?.testimonials || [])];
                    newTestimonials[index] = { ...testimonial, position: e.target.value };
                    handleContentChange('testimonials', 'testimonials', newTestimonials);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Company"
                  value={testimonial.company || ''}
                  onChange={(e) => {
                    const newTestimonials = [...(siteContent.testimonials?.testimonials || [])];
                    newTestimonials[index] = { ...testimonial, company: e.target.value };
                    handleContentChange('testimonials', 'testimonials', newTestimonials);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  label="Text"
                  value={testimonial.text || ''}
                  onChange={(e) => {
                    const newTestimonials = [...(siteContent.testimonials?.testimonials || [])];
                    newTestimonials[index] = { ...testimonial, text: e.target.value };
                    handleContentChange('testimonials', 'testimonials', newTestimonials);
                  }}
                />
              </Grid>
            </Grid>
            <ListItemSecondaryAction>
              <IconButton edge="end" onClick={() => {
                const newTestimonials = [...(siteContent.testimonials?.testimonials || [])];
                newTestimonials.splice(index, 1);
                handleContentChange('testimonials', 'testimonials', newTestimonials);
              }}>
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      <Button
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={() => {
          const newTestimonial = {
            name: '',
            position: '',
            company: '',
            text: '',
            image: null
          };
          handleContentChange('testimonials', 'testimonials', [...(siteContent.testimonials?.testimonials || []), newTestimonial]);
        }}
        sx={{ mr: 2 }}
      >
        Add Testimonial
      </Button>
      <Button
        variant="contained"
        color="primary"
        startIcon={<SaveIcon />}
        onClick={() => handleSave('testimonials', siteContent.testimonials)}
      >
        Save Testimonials
      </Button>
    </Paper>
  );

  const renderContactSection = () => (
    <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
      <Typography variant="h6" gutterBottom>Contact Information</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Email"
            value={siteContent.contact?.email || ''}
            onChange={(e) => handleContentChange('contact', 'email', e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Phone"
            value={siteContent.contact?.phone || ''}
            onChange={(e) => handleContentChange('contact', 'phone', e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Business Hours"
            value={siteContent.contact?.hours || ''}
            onChange={(e) => handleContentChange('contact', 'hours', e.target.value)}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>Address</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Street"
                value={siteContent.contact?.address?.street || ''}
                onChange={(e) => handleContentChange('contact', 'address', { ...siteContent.contact?.address, street: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="City"
                value={siteContent.contact?.address?.city || ''}
                onChange={(e) => handleContentChange('contact', 'address', { ...siteContent.contact?.address, city: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                label="State"
                value={siteContent.contact?.address?.state || ''}
                onChange={(e) => handleContentChange('contact', 'address', { ...siteContent.contact?.address, state: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                label="ZIP Code"
                value={siteContent.contact?.address?.zip || ''}
                onChange={(e) => handleContentChange('contact', 'address', { ...siteContent.contact?.address, zip: e.target.value })}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>Social Media</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="LinkedIn URL"
                value={siteContent.contact?.social?.linkedin || ''}
                onChange={(e) => handleContentChange('contact', 'social', { ...siteContent.contact?.social, linkedin: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Twitter URL"
                value={siteContent.contact?.social?.twitter || ''}
                onChange={(e) => handleContentChange('contact', 'social', { ...siteContent.contact?.social, twitter: e.target.value })}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Button
        variant="contained"
        color="primary"
        startIcon={<SaveIcon />}
        onClick={() => handleSave('contact', siteContent.contact)}
        sx={{ mt: 2 }}
      >
        Save Contact Info
      </Button>
    </Paper>
  );

  const renderFooterSection = () => (
    <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
      <Typography variant="h6" gutterBottom>Footer Content</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>Social Links</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="LinkedIn URL"
                value={siteContent.footer?.social?.linkedin || ''}
                onChange={(e) => handleContentChange('footer', 'social', { ...siteContent.footer?.social, linkedin: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Twitter URL"
                value={siteContent.footer?.social?.twitter || ''}
                onChange={(e) => handleContentChange('footer', 'social', { ...siteContent.footer?.social, twitter: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="GitHub URL"
                value={siteContent.footer?.social?.github || ''}
                onChange={(e) => handleContentChange('footer', 'social', { ...siteContent.footer?.social, github: e.target.value })}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Copyright Text"
            value={siteContent.footer?.copyright || ''}
            onChange={(e) => handleContentChange('footer', 'copyright', e.target.value)}
          />
        </Grid>
      </Grid>
      <Button
        variant="contained"
        color="primary"
        startIcon={<SaveIcon />}
        onClick={() => handleSave('footer', siteContent.footer)}
        sx={{ mt: 2 }}
      >
        Save Footer Content
      </Button>
    </Paper>
  );

  const renderEmailConfigSection = () => (
    <EmailConfig />
  );

  const renderSeoConfigSection = () => (
    <SeoConfig />
  );

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
        <Typography variant="h4">Content Management</Typography>
        <Button variant="outlined" color="primary" onClick={handleLogout}>
          Logout
        </Button>
      </Box>

      {saveStatus.show && (
        <Alert 
          severity={saveStatus.success ? "success" : "error"}
          onClose={() => setSaveStatus({ ...saveStatus, show: false })}
          sx={{ mb: 2 }}
        >
          {saveStatus.message}
        </Alert>
      )}

      <Paper sx={{ width: '100%', mb: 4 }}>
        <Tabs
          value={activeTab}
          onChange={(e, newValue) => setActiveTab(newValue)}
          variant="scrollable"
          scrollButtons="auto"
          sx={{ borderBottom: 1, borderColor: 'divider' }}
        >
          <Tab label="Home" />
          <Tab label="About" />
          <Tab label="Timeline" />
          <Tab label="Skills" />
          <Tab label="Case Studies" />
          <Tab label="Testimonials" />
          <Tab label="Contact" />
          <Tab label="Footer" />
          <Tab label="Email Config" />
          <Tab label="SEO Config" />
        </Tabs>
      </Paper>

      {activeTab === 0 && renderHomeSection()}
      {activeTab === 1 && renderAboutSection()}
      {activeTab === 2 && renderTimelineSection()}
      {activeTab === 3 && renderSkillsSection()}
      {activeTab === 4 && renderCaseStudiesSection()}
      {activeTab === 5 && renderTestimonialsSection()}
      {activeTab === 6 && renderContactSection()}
      {activeTab === 7 && renderFooterSection()}
      {activeTab === 8 && renderEmailConfigSection()}
      {activeTab === 9 && renderSeoConfigSection()}
    </Container>
  );
};

export default AdminPanel;
