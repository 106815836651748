import { useState, useEffect } from 'react';
import { content } from '../services/api';

export function useSiteContent(type = null) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const contentTypes = ['home', 'about', 'contact', 'timeline', 'skills', 'case-studies', 'testimonials', 'social', 'footer'];
        const contentData = {};
        let hasValidData = false;

        // If a specific type is requested, only fetch that one
        const typesToFetch = type ? [type] : contentTypes;

        for (const contentType of typesToFetch) {
          try {

            const response = await content.getContent(contentType);

            // Process the response
            if (contentType === 'timeline') {
              // For timeline, if it's an array, use it directly
              if (Array.isArray(response)) {
                contentData[contentType] = response;
              } else if (response.content && Array.isArray(response.content)) {
                // If it's wrapped in a content property
                contentData[contentType] = response.content;
              }
            } else {
              // For other content types, use the content property
              contentData[contentType] = response?.content || {};
            }
            hasValidData = true;

          } catch (err) {
            console.error(`[Content Hook] Error fetching ${contentType}:`, err);
            // Don't set error state here, just log it and continue with other types
          }
        }

        if (!hasValidData) {
          throw new Error('No valid content data received from the server');
        }

        setData(contentData);
        setError(null);
      } catch (err) {
        console.error('[Content Hook] Error:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchContent();
  }, [type]);

  // If a specific type was requested, return just that data
  if (type) {
    return {
      data: data[type],
      loading,
      error
    };
  }

  return { data, loading, error };
}

export default useSiteContent;
