import React from 'react';
import {
  Container,
  Title,
  Text,
  List,
  ThemeIcon,
  rem,
  Stack,
  Grid,
  Image,
  Button,
  Group,
  SimpleGrid,
} from '@mantine/core';
import { IconCircleCheck, IconArrowRight } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import classes from './SkillsSection.module.css';

export default function SkillsSection({ content }) {
  const navigate = useNavigate();
  const homeData = content?.home || {};

  return (
    <Container size="lg" py="xl">
      <Stack gap="lg">
        <Title className={classes.title} ta="center">
          What Can I Do For You?
        </Title>

        <Grid gutter="xl" align="center">
          <Grid.Col span={{ base: 12, md: 4 }}>
            <Image
              src="/images/profile-headshot.jpg"
              alt="Germaine Wilson"
              radius="md"
              className={classes.profileImage}
            />
          </Grid.Col>
          
          <Grid.Col span={{ base: 12, md: 8 }}>
            <Stack gap="lg">
              <Text size="lg" className={classes.description}>
                As a Business Analysis & <span className={classes.highlight}>Digital Transformation</span> Expert with over 15 years of experience, I specialise in helping
                businesses like yours:
              </Text>

              <List
                spacing="md"
                size="lg"
                center
                icon={
                  <ThemeIcon size={28} radius="xl" color="blue">
                    <IconCircleCheck style={{ width: rem(20), height: rem(20) }} />
                  </ThemeIcon>
                }
              >
                <List.Item>
                  <Text span fw={500}>Make Processes Easier and Better:</Text>
                  {' '}Find areas where things can be improved and create specific solutions that help save time and resources.  
                </List.Item>
                
                <List.Item>
                  <Text span fw={500}>Use the Latest Technology:</Text>
                  {' '}I help your business use modern tools like AI and easy-to-use software so you can succeed in today’s digital world.
                </List.Item>
                
                <List.Item>
                  <Text span fw={500}>Use Data for Smarter Decisions:</Text>
                  {' '}Collect and analyse data in a way that helps you find opportunities and make better choices for your business. 
                </List.Item>
                
                <List.Item>
                  <Text span fw={500}>Use Resources Wisely:</Text>
                  {' '}Move resources to where they are most needed and invest in areas where your business is naturally growing.
                </List.Item>
              </List>
            </Stack>
          </Grid.Col>
        </Grid>

        <div className={classes.statsSection}>
          <SimpleGrid cols={{ base: 1, sm: 3 }} className={classes.statsGrid}>
            {(homeData.stats || []).map((stat, index) => (
              <div key={index}>
                <Text className={classes.statValue}>
                  {stat.value}
                </Text>
                <Text className={classes.statLabel}>
                  {stat.label}
                </Text>
              </div>
            ))}
          </SimpleGrid>
        </div>

        <Text size="lg" className={classes.description} ta="center">
        If you want to create better ways to work together, enhance teamwork, or better understand how your business runs, I have a lot of experience helping achieve great results in different settings.
        </Text>

        <Text size="lg" className={classes.description} ta="center">
          Let's improve your business together. Press the button below to connect, and let's
          discuss how I can help you achieve your goals.
        </Text>

        <Text size="lg" className={classes.italicText} ta="center">
          Your business deserves a partner who understands both the big picture and the fine details. Let's make it happen.
        </Text>

        <Group justify="center" mt="xl">
          <Button
            size="xl"
            radius="md"
            className={classes.control}
            rightSection={<IconArrowRight size={20} />}
            onClick={() => navigate('/contact')}
          >
            Get Started
          </Button>
        </Group>
      </Stack>
    </Container>
  );
}
