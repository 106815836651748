import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Drawer, Stack, UnstyledButton, Group, Text } from '@mantine/core';
import {
  IconHome,
  IconTimeline,
  IconMail,
  IconX,
  IconBriefcase,
} from '@tabler/icons-react';
import classes from './AppDrawer.module.css';

const menuItems = [
  { icon: IconHome, label: 'Home', path: '/' },
  { icon: IconBriefcase, label: 'Case Studies', path: '/case-studies' },

  { icon: IconTimeline, label: 'Previous Clients', path: '/timeline' },
  { icon: IconMail, label: 'Contact', path: '/contact' },
];

export default function AppDrawer({ opened, close }) {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
    close();
  };

  return (
    <Drawer
      opened={opened}
      onClose={close}
      size="100%"
      padding="md"
      title={
        <Group justify="space-between" w="100%">
          <Text size="xl" fw={700}>Menu</Text>
          <UnstyledButton 
            onClick={close}
            className={classes.closeButton}
            style={{ 
              padding: '8px',
              borderRadius: '50%',
              backgroundColor: 'var(--mantine-color-gray-1)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <IconX size={24} />
          </UnstyledButton>
        </Group>
      }
      classNames={{
        header: classes.header,
        title: classes.title,
      }}
    >
      <Stack gap="lg">
        {menuItems.map((item) => (
          <UnstyledButton
            key={item.path}
            className={classes.link}
            onClick={() => handleNavigation(item.path)}
          >
            <Group>
              <item.icon size={24} stroke={1.5} />
              <Text size="lg">{item.label}</Text>
            </Group>
          </UnstyledButton>
        ))}
      </Stack>
    </Drawer>
  );
}
