import React from 'react';
import {
  Container,
  Title,
  Text,
  Button,
  Group,
  SimpleGrid,
  ThemeIcon,
  Box,
} from '@mantine/core';
import {
  IconChartBar,
  IconArrowRight,
  IconBulb,
  IconRefresh,
} from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import classes from './HeroSection.module.css';

export default function HeroSection({ content }) {
  const navigate = useNavigate();
  const homeData = content?.home || {};
  const features = [
    {
      icon: IconChartBar,
      title: 'Using AI to Make Better Decisions with Data',
      description: 'Turn raw data into useful information',
    },
    {
      icon: IconRefresh,
      title: 'Simplifying Work Processes and Gaining Insights',
      description: 'Make work easier and boost productivity',
    },
    {
      icon: IconBulb,
      title: 'Digital Transformation for any Industry',
      description: 'Transform your business for the future with new and innovative solutions',
    },
  ];

  return (
    <div className={classes.wrapper}>
      <Container size="lg" className={classes.mainContainer}>
        <div className={classes.inner}>
          <div className={classes.content}>
            <Title className={classes.title}>
              Helping businesses use <span className={classes.highlight}>Data & AI</span> to <span className={classes.highlight}>streamline processes</span>, <span className={classes.highlight}>improve efficiency</span>, and <span className={classes.highlight}>scale</span>
            </Title>
            
            <Text className={classes.description} c="dimmed" size="xl" lh={1.6}>
              {homeData.tagline || 'Transforming businesses through data-driven insights and innovative solutions'}
            </Text>

            <div className={classes.buttonWrapper}>
              <Button
                size="lg"
                radius="md"
                className={classes.control}
                rightSection={<IconArrowRight size={20} />}
                onClick={() => navigate('/contact')}
              >
                Get Started
              </Button>
            </div>
          </div>

          <SimpleGrid cols={{ base: 1, sm: 3 }} spacing={{ base: "md", sm: "lg" }} className={classes.features}>
            {features.map((feature, index) => (
              <Box key={index} className={classes.feature}>
                <ThemeIcon size={44} radius="md" className={classes.featureIcon}>
                  <feature.icon size={24} stroke={1.5} />
                </ThemeIcon>
                <Text fw={700} fz="lg" mb="xs">
                  {feature.title}
                </Text>
                <Text c="dimmed" fz="sm">
                  {feature.description}
                </Text>
              </Box>
            ))}
          </SimpleGrid>
        </div>
      </Container>
    </div>
  );
}
