import React from 'react';
import { Container, Text, Group } from '@mantine/core';
import { Link } from 'react-router-dom';
import classes from './Footer.module.css';

export default function Footer() {

  return (
    <footer className={classes.footer}>
      <Container size="lg">
        <Group justify="center" className={classes.inner}>
          <Text size="sm" c="dimmed" className={classes.copyright}>
            {new Date().getFullYear()} Maine Stream. All rights reserved.
          </Text>
          <Group gap="xs" className={classes.links}>
            <a href="/privacy-policy" target="_blank" rel="noopener noreferrer" className={classes.link}>
              <Text size="sm" c="dimmed">Privacy Policy</Text>
            </a>
            <Text size="sm" c="dimmed">•</Text>
            <a href="/terms-of-service" target="_blank" rel="noopener noreferrer" className={classes.link}>
              <Text size="sm" c="dimmed">Terms of Service</Text>
            </a>
          </Group>
        </Group>
      </Container>
    </footer>
  );
}
