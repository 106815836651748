import React, { useState, useEffect } from 'react';
import {
  Paper,
  Title,
  TextInput,
  Button,
  Stack,
  Group,
  Text,
  Alert,
  Switch,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconAlertCircle } from '@tabler/icons-react';
import axios from '../../utils/axios';

export default function EmailConfig() {
  const [config, setConfig] = useState({
    brevoApiKey: '',
    senderEmail: '',
    senderName: '',
    active: true
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchConfig();
  }, []);

  const fetchConfig = async () => {
    try {
      const response = await axios.get('/api/email/config');
      setConfig(response.data);
      setError(null);
    } catch (err) {
      setError('Failed to load email configuration');
      console.error('Error fetching email config:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put('/api/email/config', config);
      notifications.show({
        title: 'Success',
        message: 'Email configuration updated successfully',
        color: 'green'
      });
      setError(null);
    } catch (err) {
      notifications.show({
        title: 'Error',
        message: 'Failed to update email configuration',
        color: 'red'
      });
      console.error('Error updating email config:', err);
    }
  };

  const handleChange = (field) => (event) => {
    setConfig(prev => ({
      ...prev,
      [field]: event.target.value
    }));
  };

  if (loading) {
    return <Text>Loading...</Text>;
  }

  return (
    <Paper p="md" radius="md">
      <Title order={2} mb="lg">Email Configuration</Title>

      {error && (
        <Alert icon={<IconAlertCircle size={16} />} color="red" mb="md">
          {error}
        </Alert>
      )}

      <form onSubmit={handleSubmit}>
        <Stack>
          <TextInput
            label="Brevo API Key"
            required
            value={config.brevoApiKey}
            onChange={handleChange('brevoApiKey')}
            placeholder="Enter your Brevo API key"
          />

          <TextInput
            label="Sender Email"
            required
            value={config.senderEmail}
            onChange={handleChange('senderEmail')}
            placeholder="noreply@maine-stream.com"
          />

          <TextInput
            label="Sender Name"
            required
            value={config.senderName}
            onChange={handleChange('senderName')}
            placeholder="Maine Stream"
          />

          <Switch
            label="Active"
            checked={config.active}
            onChange={(event) => setConfig(prev => ({
              ...prev,
              active: event.currentTarget.checked
            }))}
          />

          <Group justify="flex-end" mt="md">
            <Button type="submit">
              Save Configuration
            </Button>
          </Group>
        </Stack>
      </form>
    </Paper>
  );
}
