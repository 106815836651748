import React from 'react';
import api from '../../config/api';
import {
  Container,
  Title,
  Text,
  SimpleGrid,
  TextInput,
  Textarea,
  Button,
  Group,
  ActionIcon,
  Paper,
  ThemeIcon,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import {
  IconBrandLinkedin,
  IconMail,
  IconMapPin,
  IconPhone,
} from '@tabler/icons-react';
import classes from './ContactSection.module.css';

export default function ContactSection({ content }) {
  const contactData = content?.contact || {};

  const linkedInUrl = contactData.social?.linkedin || '#';

  const contactInfo = [
    {
      title: 'Email',
      description: contactData.email || 'contact@maine-stream.com',
      icon: IconMail,
    },
    {
      title: 'Phone',
      description: contactData.phone || '+1 (555) 123-4567',
      icon: IconPhone,
    },
    {
      title: 'Location',
      description: contactData.address ? `${contactData.address.city}, ${contactData.address.county}` : 'Portland, Maine',
      icon: IconMapPin,
    },
    {
      title: 'LinkedIn',
      description: 'Connect with me',
      icon: IconBrandLinkedin,
      link: linkedInUrl,
    },
  ];

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const data = {
      name: formData.get('name'),
      email: formData.get('email'),
      message: formData.get('message')
    };

    try {
      const response = await api.post('/api/email/send-contact', data);
      notifications.show({
        title: 'Success',
        message: response.data.message || 'Thank you for your message. I will get back to you soon!',
        color: 'green',
        autoClose: 5000
      });
      event.target.reset();
    } catch (error) {
      console.error('Failed to send message:', error);
      console.error('Error details:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
        headers: error.response?.headers,
        config: error.config
      });
      notifications.show({
        title: 'Error',
        message: error.response?.data?.message || 'Failed to send message. Please try again later.',
        color: 'red',
        autoClose: 5000
      });
    }
  };



  return (
    <Container size={1100} px={{ base: 'xs', sm: 'xl' }} className={classes.wrapper}>
      <SimpleGrid cols={{ base: 1, sm: 2 }} spacing={50}>
        <div className={classes.content}>
          <Title className={classes.title} style={{ textAlign: 'left' }}>Get in Touch</Title>
          <Text className={classes.description} mt="sm" mb={30} style={{ textAlign: 'left' }}>
            Leave a message and I'll get back to you as soon as possible. Let's discuss how I can help transform your business.
          </Text>

          <SimpleGrid cols={1} spacing="xl" mt={50}>
            {contactInfo.map((item, index) => (
              <Group key={index} wrap="nowrap">
                <ThemeIcon
                  size={40}
                  radius="md"
                  variant="filled"
                  className={classes.itemIcon}
                >
                  <item.icon size={24} stroke={1.5} />
                </ThemeIcon>

                <div>
                  <Text size="sm" fw={700} className={classes.itemTitle}>
                    {item.title}
                  </Text>
                  {item.link ? (
                    <Text 
                      className={classes.itemDescription}
                      component="a"
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.description}
                    </Text>
                  ) : (
                    <Text className={classes.itemDescription}>{item.description}</Text>
                  )}
                </div>
              </Group>
            ))}
          </SimpleGrid>


        </div>

        <Paper shadow="md" radius="lg" className={classes.form} p={30}>
          <form onSubmit={handleSubmit} className={classes.form}>
            <TextInput
              label="Name"
              name="name"
              placeholder="Your name"
              required
              classNames={{ input: classes.input, label: classes.inputLabel }}
            />
            <TextInput
              label="Email"
              name="email"
              placeholder="Your email"
              required
              classNames={{ input: classes.input, label: classes.inputLabel }}
            />
            <Textarea
              required
              label="Message"
              name="message"
              placeholder="Your message"
              minRows={4}
              maxRows={8}
              classNames={{ input: classes.input, label: classes.inputLabel }}
            />
            <Group justify="flex-end" mt="md">
              <Button type="submit" className={classes.control}>
                Send message
              </Button>
            </Group>
          </form>
        </Paper>
      </SimpleGrid>
    </Container>
  );
}
