import React from 'react';
import { useDisclosure } from '@mantine/hooks';
import { Container, Group, UnstyledButton, Text } from '@mantine/core';
import { IconMenu2, IconCode, IconX } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import AppDrawer from './AppDrawer';
import classes from './MobileHeader.module.css';

export default function MobileHeader() {
  const [opened, { open, close, toggle }] = useDisclosure(false);

  return (
    <>
      <header className={classes.header}>
        <Container size="lg">
          <Group justify="space-between" h={60}>
            <Link to="/" className={classes.logo}>
              <Group gap={8} align="center">
                <IconCode size={30} stroke={1.5} />
                <Text size="xl" fw={900}>Maine-Stream</Text>
              </Group>
            </Link>
            <UnstyledButton 
              onClick={toggle}
              className={classes.menuButton}
            >
              {opened ? (
                <IconX size={24} stroke={1.5} />
              ) : (
                <IconMenu2 size={24} stroke={1.5} />
              )}
            </UnstyledButton>
          </Group>
        </Container>
      </header>
      <AppDrawer opened={opened} close={close} />
    </>
  );
}
