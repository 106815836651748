import React, { useEffect, useState } from 'react';
import {
  Container,
  Title,
  Text,
  Card,
  SimpleGrid,
  Badge,
  Group,
  Image,
  Center,
  Loader,
  Box,
  Button,
} from '@mantine/core';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconArrowRight } from '@tabler/icons-react';
import { useSiteContent } from '../hooks/useSiteContent';
import CaseStudyModal from '../components/modals/CaseStudyModal';
import classes from './CaseStudies.module.css';

export default function CaseStudies() {
  const { data, loading, error } = useSiteContent('case-studies');
  const [selectedStudy, setSelectedStudy] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const studyTitle = params.get('study');
    const studyClient = params.get('client');
    
    if (studyTitle && data?.studies) {
      // First try to find by both title and client
      let study = studyClient ? data.studies.find(
        (s) => s.title === decodeURIComponent(studyTitle) && 
              s.client === decodeURIComponent(studyClient)
      ) : null;
      
      // If not found or client not provided, try to find by title only
      if (!study) {
        study = data.studies.find(
          (s) => s.title === decodeURIComponent(studyTitle)
        );
      }
      if (study) {
        setSelectedStudy(study);
      }
    }
  }, [location.search, data]);

  if (loading) {
    return (
      <Center style={{ height: '100vh' }}>
        <Loader size="lg" />
      </Center>
    );
  }

  if (error) {
    return (
      <Center style={{ height: '100vh' }}>
        <Text c="red">{error}</Text>
      </Center>
    );
  }

  const studies = data?.studies || [];
  const hasNoStudies = !studies.length;

  const handleStudyClick = (study) => {
    setSelectedStudy(study);
    const params = new URLSearchParams();
    params.set('study', encodeURIComponent(study.title));
    params.set('client', encodeURIComponent(study.client));
    navigate(`/case-studies?${params.toString()}`, {
      replace: true,
    });
  };

  const handleCloseModal = () => {
    setSelectedStudy(null);
    navigate('/case-studies', { replace: true });
  };

  const cards = studies.map((study) => (
    <Card 
      key={`${study.title}-${study.client}`} 
      p="lg" 
      radius="md" 
      className={classes.card}
      onClick={() => handleStudyClick(study)}
    >
      <Card.Section>
        <Box style={{ position: 'relative', height: 120, display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }}>
          <Image
            src={study.image || '/images/placeholder-case-study.jpg'}
            height={100}
            fit="contain"
            alt={study.title}
            style={{ padding: '1rem', maxWidth: '100%', objectFit: 'contain' }}
          />
        </Box>
      </Card.Section>

      <Group justify="space-between" mt="md" mb="xs">
        <Title order={3}>{study.title}</Title>
        <Badge variant="light" color="blue">
          {study.industry}
        </Badge>
      </Group>

      <Text size="sm" c="dimmed" mb="md" lineClamp={3}>
        {study.description}
      </Text>

      <Group justify="space-between" mt="md">
        <Text size="sm" fw={500} c="dimmed">
          {study.client}
        </Text>
        <Text size="sm" c="dimmed">
          {study.year}
        </Text>
      </Group>

      <Group justify="flex-end" mt="md">
        <Button variant="light" rightSection={<IconArrowRight size={16} />}>
          Learn More
        </Button>
      </Group>
    </Card>
  ));

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.header}>
        <Container size="lg">
          <Title className={classes.title}>
            {'Case Studies'}
          </Title>
          <Text className={classes.description} size="xl" mt="xl">
            {data?.description || 'Detailed analysis of successful projects and their outcomes'}
          </Text>
        </Container>
      </Box>

      <Container size="lg" py="xl">
        {hasNoStudies ? (
          <Center py="xl">
            <Box ta="center">
              <Text size="lg" fw={500} mb="md">
                No case studies available at the moment
              </Text>
              <Text c="dimmed">
                Please check back later for updates on my latest projects.
              </Text>
            </Box>
          </Center>
        ) : (
          <SimpleGrid cols={{ base: 1, sm: 2, md: 3 }} spacing="xl">
            {cards}
          </SimpleGrid>
        )}
      </Container>

      <CaseStudyModal
        study={selectedStudy}
        opened={!!selectedStudy}
        onClose={handleCloseModal}
      />
    </Box>
  );
}
