import React from 'react';
import { Group, Container, Text, rem } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';
import { IconCode } from '@tabler/icons-react';
import classes from './Header.module.css';

const links = [
  { link: '/', label: 'Home' },
  { link: '/case-studies', label: 'Case Studies' },
  { link: '/timeline', label: 'Client Success Stories' },
  { link: '/contact', label: 'Contact' },
];

export default function Header() {
  const location = useLocation();
  const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === 'true';

  const items = !isMaintenanceMode ? links.map((link) => (
    <Link
      key={link.label}
      to={link.link}
      className={classes.link}
      data-active={location.pathname === link.link || undefined}
    >
      {link.label}
    </Link>
  )) : null;

  return (
    <header className={classes.header} style={{ '--header-mobile-display': isMaintenanceMode ? 'block' : 'none' }}>
      <Container size="lg" className={classes.inner}>
        <div className={classes.logo}>
          <Group gap={rem(8)} align="center">
            <IconCode size={30} stroke={1.5} />
            <Text size="xl" fw={900} className={classes.logoText}>
              Maine-Stream
            </Text>
          </Group>
        </div>
        {!isMaintenanceMode && (
          <Group gap={rem(40)} className={classes.links} visibleFrom="sm">
            {items}
          </Group>
        )}
      </Container>
    </header>
  );
}
