import React from 'react';
import {
  Container,
  Title,
  Text,
  ThemeIcon,
  Paper,
  SimpleGrid,
  List,
  Group,
  Card,
  Badge,
  Image,
  Box,
} from '@mantine/core';
import {
  IconRobot,
  IconBrain,
  IconChartInfographic,
  IconCheck,
  IconBrandPowershell,
  IconDeviceAnalytics,
  IconCode,
} from '@tabler/icons-react';
import classes from './AIAutomation.module.css';

export default function AIAutomationPage() {
  const technologies = [
    {
      name: 'Nintex',
      description: 'Process automation and workflow management',
      type: 'RPA',
      icon: IconBrandPowershell,
    },
    {
      name: 'UiPath',
      description: 'Robotic Process Automation (RPA)',
      type: 'RPA',
      icon: IconRobot,
    },
    {
      name: 'Power BI',
      description: 'Business intelligence and data visualization',
      type: 'Analytics',
      icon: IconDeviceAnalytics,
    },
    {
      name: 'Custom AI Solutions',
      description: 'Tailored AI implementations',
      type: 'AI',
      icon: IconBrain,
    },
  ];

  const implementationSteps = [
    {
      title: 'Process Analysis',
      description: 'Deep dive into existing workflows to identify automation opportunities',
    },
    {
      title: 'AI Strategy Development',
      description: 'Design tailored AI solutions that align with business objectives',
    },
    {
      title: 'Proof of Concept',
      description: 'Rapid prototyping to demonstrate value and gather feedback',
    },
    {
      title: 'Implementation',
      description: 'Systematic rollout with continuous monitoring and optimization',
    },
  ];

  return (
    <Container size="lg" py="xl">
      {/* Why AI & Automation Matter */}
      <Title className={classes.title} order={1}>
        AI & Automation Solutions
      </Title>
      <Text className={classes.description} c="dimmed">
        Transforming businesses through intelligent automation and AI-driven innovation
      </Text>

      <SimpleGrid cols={{ base: 1, md: 2 }} spacing={50} mt={50}>
        <div>
          <Title order={2} mb="md">Why AI & Automation Matter</Title>
          <Text mb="xl">
            In today's rapidly evolving business landscape, AI and automation aren't just advantages—they're necessities. 
            Organizations that leverage these technologies effectively see:
          </Text>
          <List
            spacing="sm"
            size="lg"
            icon={
              <ThemeIcon size={24} radius="xl" color="blue">
                <IconCheck style={{ width: 16, height: 16 }} />
              </ThemeIcon>
            }
          >
            <List.Item>40-60% reduction in process execution time</List.Item>
            <List.Item>Significant decrease in human error rates</List.Item>
            <List.Item>Enhanced data-driven decision making</List.Item>
            <List.Item>Improved regulatory compliance</List.Item>
            <List.Item>Better resource allocation and cost savings</List.Item>
          </List>
        </div>
        <Paper shadow="md" p="xl" radius="md" style={{ background: 'linear-gradient(45deg, #228BE6, #40C057)' }}>
          <Text c="white" fz="xl" fw={700} mb="md">Success Metrics</Text>
          <SimpleGrid cols={2} spacing="lg">
            <div>
              <Text c="white" fz="xl" fw={700}>60%</Text>
              <Text c="white" opacity={0.7}>Cost Reduction</Text>
            </div>
            <div>
              <Text c="white" fz="xl" fw={700}>85%</Text>
              <Text c="white" opacity={0.7}>Error Reduction</Text>
            </div>
            <div>
              <Text c="white" fz="xl" fw={700}>45%</Text>
              <Text c="white" opacity={0.7}>Efficiency Gain</Text>
            </div>
            <div>
              <Text c="white" fz="xl" fw={700}>24/7</Text>
              <Text c="white" opacity={0.7}>Operation</Text>
            </div>
          </SimpleGrid>
        </Paper>
      </SimpleGrid>

      {/* How I Implement AI */}
      <Title order={2} mt={80} mb="xl">How I Implement AI to Improve Efficiency</Title>
      <SimpleGrid cols={{ base: 1, sm: 2, md: 4 }} spacing="lg">
        {implementationSteps.map((step, index) => (
          <Card key={index} shadow="sm" padding="lg" radius="md" withBorder>
            <Text fw={700} mb="xs">
              {step.title}
            </Text>
            <Text size="sm" c="dimmed">
              {step.description}
            </Text>
          </Card>
        ))}
      </SimpleGrid>

      {/* Technologies */}
      <Title order={2} mt={80} mb="xl">AI, RPA, and Low-Code Platforms</Title>
      <SimpleGrid cols={{ base: 1, sm: 2 }} spacing="lg">
        {technologies.map((tech, index) => (
          <Card key={index} shadow="sm" padding="lg" radius="md" withBorder>
            <Group justify="space-between" mb="md">
              <div>
                <Text fw={700}>{tech.name}</Text>
                <Badge color="blue" variant="light">
                  {tech.type}
                </Badge>
              </div>
              <ThemeIcon size={40} radius="md" variant="light">
                <tech.icon style={{ width: 24, height: 24 }} />
              </ThemeIcon>
            </Group>
            <Text size="sm" c="dimmed">
              {tech.description}
            </Text>
          </Card>
        ))}
      </SimpleGrid>

      {/* Case Study */}
      <Title order={2} mt={80} mb="xl">Featured Case Study: AI-Powered CRM Transformation</Title>
      <Card shadow="sm" padding="xl" radius="md" withBorder>
        <SimpleGrid cols={{ base: 1, md: 2 }} spacing="xl">
          <div>
            <Badge color="blue" variant="light" size="lg" mb="md">
              Healthcare Industry
            </Badge>
            <Title order={3} mb="md">
              AI-Driven Patient Management System
            </Title>
            <Text mb="md">
              Implemented an intelligent CRM system for a counselling practice, featuring AI-powered 
              scheduling, automated patient communications, and predictive analytics for better resource allocation.
            </Text>
            <List
              spacing="sm"
              icon={
                <ThemeIcon size={24} radius="xl" color="blue">
                  <IconCheck style={{ width: 16, height: 16 }} />
                </ThemeIcon>
              }
            >
              <List.Item>Reduced scheduling conflicts by 95%</List.Item>
              <List.Item>Automated 80% of routine patient communications</List.Item>
              <List.Item>Improved resource utilization by 40%</List.Item>
              <List.Item>Enhanced patient satisfaction scores by 60%</List.Item>
            </List>
          </div>
          <Box>
            <Image
              src="/images/ai-automation-case-study.svg"
              alt="AI Automation Case Study"
              style={{ maxWidth: '100%', height: 'auto' }}
            />
          </Box>
        </SimpleGrid>
      </Card>
    </Container>
  );
}
