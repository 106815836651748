import React from 'react';
import {
  Modal,
  Title,
  Text,
  Group,
  Badge,
  List,
  ThemeIcon,
  Box,
  Stack,
  Image,
  Paper,
  ScrollArea,
  ActionIcon,
} from '@mantine/core';
import { IconCheck, IconX } from '@tabler/icons-react';
import classes from './CaseStudyModal.module.css';

export default function CaseStudyModal({ study, opened, onClose }) {
  if (!study) return null;

  const isMobile = window.innerWidth <= 768;

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      size="95vw"
      fullScreen={isMobile}
      padding={{ base: 'xs', sm: 'md', md: 'xl' }}
      centered={!isMobile}
      title={
        <Group justify="space-between" align="flex-start" wrap="nowrap">
          <Stack gap="xs">
            <Title order={2} size="h3">
              {study.title}
            </Title>
            <Group gap="xs" wrap="wrap">
              <Badge size="lg" variant="filled">
                {study.client}
              </Badge>
              <Badge size="lg" variant="light" color="blue">
                {study.industry}
              </Badge>
              <Badge size="lg" variant="outline">
                {study.year}
              </Badge>
            </Group>
          </Stack>
          {isMobile && (
            <ActionIcon 
              variant="subtle" 
              onClick={onClose}
              size="lg"
              aria-label="Close modal"
            >
              <IconX />
            </ActionIcon>
          )}
        </Group>
      }
      classNames={{
        title: classes.modalTitle,
        header: classes.modalHeader,
        body: classes.modalBody,
        inner: classes.modalInner,
      }}
    >
      <ScrollArea h={isMobile ? 'calc(100vh - 120px)' : 'auto'} type="auto">
        <Box p={{ base: 'xs', sm: 'md' }}>
          {study.image && (
            <Box mb="md" style={{ maxWidth: '400px' }}>
              <Image
                src={study.image}
                alt={study.title}
                fit="contain"
                radius="md"
              />
            </Box>
          )}
          
          <Stack gap="md">
            <Box>
              <Text size="lg" fw={500} mb="xs" c="dimmed">
                Overview
              </Text>
              <Text size="md">
                {study.description}
              </Text>
            </Box>

            <Paper withBorder p="md" radius="md">
              <Text size="lg" fw={500} mb="xs" c="dimmed">
                Challenges
              </Text>
              <Text size="md">
                {study.challenge}
              </Text>
            </Paper>

            <Paper withBorder p="md" radius="md">
              <Text size="lg" fw={500} mb="xs" c="dimmed">
                Solution
              </Text>
              <Text size="md">
                {study.solution}
              </Text>
            </Paper>

            <Paper withBorder p="md" radius="md">
              <Text size="lg" fw={500} mb="sm" c="dimmed">
                Key Results
              </Text>
              <List
                spacing="xs"
                size="md"
                icon={
                  <ThemeIcon color="teal" size={22} radius="xl">
                    <IconCheck size={12} />
                  </ThemeIcon>
                }
              >
                {Array.isArray(study.results) 
                  ? study.results.map((result, index) => (
                      <List.Item key={index}>{result}</List.Item>
                    ))
                  : study.results?.split('. ').map((result, index) => (
                      <List.Item key={index}>{result}</List.Item>
                    ))
                }
              </List>
            </Paper>

            {study.testimonial && (
              <Paper className={classes.testimonial}>
                <Text size="lg" fs="italic" mb="xs">
                  "{study.testimonial.quote}"
                </Text>
                <Group gap="xs" mt="md">
                  <Text size="sm" fw={600}>
                    {study.testimonial.author}
                  </Text>
                  {study.testimonial.position && (
                    <Text size="sm" c="dimmed">
                      • {study.testimonial.position}
                    </Text>
                  )}
                </Group>
              </Paper>
            )}
          </Stack>
        </Box>
      </ScrollArea>
    </Modal>
  );
}
