import React from 'react';
import {
  Container,
  Transition,
  Loader,
  Center,
} from '@mantine/core';
import { useInView } from 'react-intersection-observer';
import { useSiteContent } from '../hooks/useSiteContent';
import HeroSection from '../components/sections/HeroSection';
import SkillsSection from '../components/sections/SkillsSection';
import CaseStudiesSection from '../components/sections/CaseStudiesSection';
import ContactSection from '../components/sections/ContactSection';
import styles from './Landing.module.css';

const ScrollAnimatedSection = ({ children, transition = "slide-up" }) => {
  const { ref, inView } = useInView({
    threshold: 0.1,  // Lower threshold to trigger earlier
    triggerOnce: true,
    initialInView: false,  // Ensure animation runs
  });

  return (
    <div ref={ref} style={{ minHeight: '50px' }}>  {/* Ensure div has height even when empty */}
      <div style={{ opacity: inView ? 1 : 0, transition: 'opacity 0.4s ease-in-out' }}>
        <Transition mounted={true} transition={transition} duration={400}>
          {(styles) => (
            <div style={styles}>
              {children}
            </div>
          )}
        </Transition>
      </div>
    </div>
  );
};

export default function Landing() {
  const { data: content, loading, error } = useSiteContent();
  // Mount immediately but control content visibility
  const [contentReady, setContentReady] = React.useState(false);

  React.useEffect(() => {
    if (!loading && !error && content) {
      // Small delay to ensure smooth transition
      setTimeout(() => setContentReady(true), 100);
    }
  }, [loading, error, content]);

  if (loading) {
    return (
      <Center style={{ height: '100vh', background: 'var(--mantine-color-body)' }}>
        <Loader size="lg" color="blue" type="dots" />
      </Center>
    );
  }

  if (error) {
    return (
      <Center style={{ height: '100vh', background: 'var(--mantine-color-body)' }}>
        <div>Error loading content</div>
      </Center>
    );
  }

  return (
    <div className={styles.wrapper}>
      <Container size={1200} px={{ base: 'xs', sm: 'xl' }} className={styles.container}>
        <div className={styles.inner}>
          {/* Hero section fades in on load */}
          <Transition mounted={contentReady} transition="fade" duration={400}>
            {(styles) => (
              <div style={styles}>
                <HeroSection content={content} />
              </div>
            )}
          </Transition>

          {/* Other sections animate on scroll */}
          <div className={styles.section}>
            <ScrollAnimatedSection transition="slide-right">
              <SkillsSection content={content} />
            </ScrollAnimatedSection>
          </div>

          <div className={styles.section}>
            <ScrollAnimatedSection transition="slide-left">
              <CaseStudiesSection content={content} />
            </ScrollAnimatedSection>
          </div>

          <div className={styles.section}>
            <ScrollAnimatedSection>
              <ContactSection content={content} />
            </ScrollAnimatedSection>
          </div>
        </div>
      </Container>
    </div>
  );
}
