import React from 'react';
import { Container, Title, Text, Card, SimpleGrid, Badge, Group, Button } from '@mantine/core';
import { IconArrowRight } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import classes from './CaseStudiesSection.module.css';
import sharedClasses from './shared.module.css';

export default function CaseStudiesSection({ content }) {
  const navigate = useNavigate();
  const caseStudiesContent = content?.['case-studies'] || content;
  const featuredStudies = caseStudiesContent?.studies?.filter(study => study.featured) || [];

  if (!caseStudiesContent || !featuredStudies.length) {
    return null;
  }

  const cards = featuredStudies.slice(0, 3).map((study) => (
    <Card 
      key={study.title} 
      p="md" 
      radius="md" 
      className={classes.card}
      onClick={() => navigate(`/case-studies?study=${encodeURIComponent(study.title)}&client=${encodeURIComponent(study.client)}`)}
    >
      <Group justify="space-between" mb="xs">
        <Title order={3} className={classes.cardTitle}>{study.title}</Title>
        <Badge variant="light" color="blue">
          {study.industry}
        </Badge>
      </Group>

      <Text size="sm" c="dimmed" mb="md" lineClamp={2}>
        {study.description}
      </Text>

      <Group justify="space-between" mt="auto" style={{ width: '100%' }}>
        <Group gap="xs">
          <Text size="sm" c="dimmed">
            {study.year}
          </Text>
          <Text size="sm" c="dimmed">•</Text>
          <Button 
            variant="light" 
            size="compact-sm"
            rightSection={<IconArrowRight size={16} />}
          >
            Learn More
          </Button>
        </Group>
      </Group>
    </Card>
  ));

  return (
    <Container size="lg" py="xl">
      <div className={classes.header}>
        <Title className={sharedClasses.sectionTitle}>{caseStudiesContent.title}</Title>
        <Text c="dimmed" size="lg" className={classes.description}>
          {caseStudiesContent.description}
        </Text>
        <Button 
          variant="outline" 
          size="md"
          onClick={() => navigate('/case-studies')}
          rightSection={<IconArrowRight size={16} />}
          className={classes.viewAllButton}
        >
          View All Case Studies
        </Button>
      </div>

      <SimpleGrid cols={{ base: 1, sm: 2, lg: 3 }} spacing={{ base: 'md', sm: 'xl' }}>
        {cards}
      </SimpleGrid>
    </Container>
  );
}
