import React, { useState, useEffect } from 'react';
import {
  Container,
  Title,
  Text,
  Modal,
  Badge,
  Group,
  ThemeIcon,
  SimpleGrid,
  Stack,
  Space,
  Divider,
  List,
  ScrollArea,
  Box,
  ActionIcon,
  Paper,
  Card,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconBriefcase, IconX, IconCheck } from '@tabler/icons-react';
import { useSiteContent } from '../hooks/useSiteContent';
import classes from './Timeline.module.css';

function ClientModal({ opened, close, client }) {
  if (!client) return null;

  return (
    <Modal
      opened={opened}
      onClose={close}
      size="95vw"
      title={<Title order={2}>{client.company}</Title>}
    >
      <Stack>
        <Text size="lg">{client.description}</Text>

        {client.achievements?.length > 0 && (
          <>
            <Title order={3} mt="xl">Impact & Achievements</Title>
            <List
              spacing="md"
              icon={
                <ThemeIcon color="blue" size={24} radius="xl">
                  <IconCheck style={{ width: 16, height: 16 }} />
                </ThemeIcon>
              }
            >
              {client.achievements.map((achievement, index) => (
                <List.Item key={index}>{achievement}</List.Item>
              ))}
            </List>
          </>
        )}

        {client.technologies?.length > 0 && (
          <>
            <Title order={3} mt="xl">Solutions & Technologies</Title>
            <Group gap="xs">
              {client.technologies.map((tech, index) => (
                <Badge key={index} variant="light" size="lg">
                  {tech}
                </Badge>
              ))}
            </Group>
          </>
        )}
      </Stack>
    </Modal>
  );
}

export default function PreviousClientsPage() {
  const { data: clients, loading, error } = useSiteContent('timeline');

  const [selectedClient, setSelectedClient] = useState(null);
  const [opened, { open, close }] = useDisclosure(false);

  if (loading) {
    return (
      <Container className={classes.wrapper}>
        <Text>Loading...</Text>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className={classes.wrapper}>
        <Text c="red">Error loading content</Text>
      </Container>
    );
  }

  const handleClientClick = (client) => {
    setSelectedClient(client);
    open();
  };

  return (
    <Container size="lg" className={classes.wrapper}>
      <Title className={classes.title}>Client Success Stories</Title>
      <Text className={classes.subtitle}>
        Delivering impactful solutions and driving positive change for organizations across various industries.
      </Text>

      <SimpleGrid
        cols={{ base: 1, sm: 2, md: 3 }}
        spacing="xl"
        className={classes.grid}
      >
        {Array.isArray(clients) && clients.map((client, index) => (
          <Card
            key={index}
            p="xl"
            radius="md"
            className={classes.clientCard}
            onClick={() => handleClientClick(client)}
          >
            <Stack>
              <Group justify="space-between" align="flex-start">
                <Text size="lg" fw={700} className={classes.cardTitle}>
                  {client.company}
                </Text>
                <ThemeIcon size={40} radius="md" color="blue">
                  <IconBriefcase style={{ width: 24, height: 24 }} />
                </ThemeIcon>
              </Group>
              <Text size="sm" c="dimmed" lineClamp={3}>
                {client.description}
              </Text>
              {client.achievements?.length > 0 && (
                <Text size="sm" c="blue.6">
                  {client.achievements.length} Key Achievements →
                </Text>
              )}
            </Stack>
          </Card>
        ))}
      </SimpleGrid>

      <ClientModal
        opened={opened}
        close={close}
        client={selectedClient}
      />
    </Container>
  );
}
