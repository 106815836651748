import React from 'react';
import { Container, Title, Text } from '@mantine/core';

export default function TermsOfService() {
  return (
    <Container size="lg" py="xl">
      <Title order={1} mb="xl">Terms of Service</Title>
      <Text mb="md">Last Updated: 7th Feb 2025</Text>
      <Text mb="md">These Terms of Service ("Terms") govern your access and use of Maine Stream's services, including our website (www.maine-stream.com) and communication channels such as WhatsApp Business API.</Text>
      
      <Title order={2} mb="md">1. Acceptance of Terms</Title>
      <Text mb="md">By using our services, you agree to these Terms. If you do not agree, please refrain from using our services.</Text>
      
      <Title order={2} mb="md">2. Use of Services</Title>
      <Text>You must:</Text>
      <Text>• Use our services only for lawful purposes</Text>
      <Text>• Use WhatsApp Business API for business inquiries and customer support only</Text>
      <Text mb="md">• Avoid misuse, including spam, abuse, or illegal activities</Text>
      
      <Title order={2} mb="md">3. Data Collection and Privacy</Title>
      <Text>• Personal data collected through our services is processed in accordance with our Privacy Policy</Text>
      <Text mb="md">• We do not share your data with third parties</Text>
      
      <Title order={2} mb="md">4. Service Availability</Title>
      <Text>• We strive to maintain uninterrupted access to our services but do not guarantee availability at all times</Text>
      <Text mb="md">• We may modify or discontinue services without prior notice</Text>
      
      <Title order={2} mb="md">5. Limitation of Liability</Title>
      <Text>• We are not responsible for any damages resulting from the use or inability to use our services</Text>
      <Text mb="md">• Our services are provided "as is" without warranties of any kind</Text>
      
      <Title order={2} mb="md">6. Changes to Terms</Title>
      <Text mb="md">We may update these Terms periodically. Continued use of our services constitutes acceptance of the revised Terms.</Text>
      
      <Title order={2} mb="md">7. Contact Us</Title>
      <Text>For any inquiries regarding these Terms, contact us at:</Text>
      <Text>Email: info@maine-stream.com</Text>
      <Text>Website: www.maine-stream.com</Text>
    </Container>
  );
}
