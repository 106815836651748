import React, { createContext, useContext, useState, useEffect } from 'react';
import { content as contentApi } from '../services/api';

const SiteContext = createContext();

export const SiteProvider = ({ children }) => {
  const [siteContent, setSiteContent] = useState({
    home: null,
    about: null,
    contact: null,
    social: null,
    footer: null,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAllContent = async () => {
      try {
        const types = ['home', 'about', 'contact', 'social', 'footer'];
        const responses = await Promise.all(
          types.map(type => contentApi.getContent(type))
        );

        const newContent = {};
        types.forEach((type, index) => {
          newContent[type] = responses[index]?.content || null;
        });

        setSiteContent(newContent);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching site content:', err);
        setError(err);
        setLoading(false);
      }
    };

    fetchAllContent();
  }, []);

  return (
    <SiteContext.Provider value={{ content: siteContent, loading, error }}>
      {children}
    </SiteContext.Provider>
  );
};

export const useSiteContent = () => {
  const context = useContext(SiteContext);
  if (!context) {
    throw new Error('useSiteContent must be used within a SiteProvider');
  }
  return context;
};

export default SiteContext;
