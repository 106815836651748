import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import { MantineProvider, createTheme } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { HelmetProvider } from 'react-helmet-async';
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';

// Components
import AppRoutes from './routes';
import Header from './components/Header';
import Footer from './components/Footer';
import MobileHeader from './components/navigation/MobileHeader';
import MaintenanceHero from './components/sections/MaintenanceHero';

// Context
import { SiteProvider } from './context/SiteContext';
import { AuthProvider } from './contexts/AuthContext';
import classes from './App.module.css';
import Analytics from './components/Analytics';

const theme = createTheme({
  /** Your theme override here */
});

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Error caught by boundary:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ padding: '20px', textAlign: 'center' }}>
          <h2>Something went wrong.</h2>
          <p>Please try refreshing the page.</p>
          <pre style={{ textAlign: 'left', margin: '20px', padding: '10px', background: '#f5f5f5' }}>
            {this.state.error?.toString()}
          </pre>
        </div>
      );
    }

    return this.props.children;
  }
}

export default function App() {
  const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === 'true';

  if (isMaintenanceMode) {
    return (
      <BrowserRouter>
        <HelmetProvider>
          <MantineProvider theme={theme}>
            <Notifications position="top-right" zIndex={2077} />
            <Analytics />
            <div className={classes.wrapper}>
              <Header />
              <main>
                <Routes>
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="/terms-of-service" element={<TermsOfService />} />
                  <Route path="*" element={<MaintenanceHero />} />
                </Routes>
              </main>
              <Footer />
            </div>
          </MantineProvider>
        </HelmetProvider>
      </BrowserRouter>
    );
  }

  return (
    <ErrorBoundary>
      <BrowserRouter>
        <HelmetProvider>
          <MantineProvider theme={theme}>
            <Notifications position="top-right" zIndex={2077} />
            <Analytics />
            <AuthProvider>
              <SiteProvider>
                <div className={classes.wrapper}>
                  <MobileHeader />
                  <Header />
                  <main>
                    <AppRoutes />
                  </main>
                  <Footer />
                </div>
              </SiteProvider>
            </AuthProvider>
          </MantineProvider>
        </HelmetProvider>
      </BrowserRouter>
    </ErrorBoundary>
  );
}
