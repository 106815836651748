import React from 'react';
import { Container, Title, Text } from '@mantine/core';

export default function PrivacyPolicy() {
  return (
    <Container size="lg" py="xl">
      <Title order={1} mb="xl">Privacy Policy</Title>
      <Text mb="md">Last Updated: 7th Feb 2025</Text>
      <Text mb="md">Welcome to Maine Stream ("we," "our," "us"). Your privacy is important to us. This Privacy Policy explains how we collect, use, and protect your information when you use our services, including WhatsApp Business API integration.</Text>
      
      <Title order={2} mb="md">1. Information We Collect</Title>
      <Text>When you interact with us through our website (www.maine-stream.com) or WhatsApp Business API, we may collect the following information:</Text>
      <Text>• Personal Information: Name, phone number, email address, and any details you provide when contacting us.</Text>
      <Text>• Usage Data: Interaction history with our services for customer support and service improvement.</Text>
      
      <Title order={2} mb="md">2. How We Use Your Information</Title>
      <Text>We use the collected data to:</Text>
      <Text>• Respond to inquiries and provide customer support.</Text>
      <Text>• Facilitate communication via WhatsApp Business API.</Text>
      <Text>• Improve our services and maintain security.</Text>
      <Text>• Comply with legal and regulatory requirements.</Text>
      
      <Title order={2} mb="md">3. Data Storage and Security</Title>
      <Text>• All collected data is stored securely within our CRM system and is never shared with third parties.</Text>
      <Text>• We implement industry-standard security measures to protect against unauthorized access.</Text>
      
      <Title order={2} mb="md">4. Your Rights and Choices</Title>
      <Text>You have the right to:</Text>
      <Text>• Request access to the information we hold about you.</Text>
      <Text>• Request correction or deletion of your data.</Text>
      <Text>• Withdraw consent for communications at any time by contacting us.</Text>
      
      <Title order={2} mb="md">5. Changes to This Privacy Policy</Title>
      <Text>We may update this Privacy Policy from time to time. The latest version will always be available on our website.</Text>
      
      <Title order={2} mb="md">6. Contact Us</Title>
      <Text>If you have any questions about this Privacy Policy, please contact us at:</Text>
      <Text>Email: info@maine-stream.com</Text>
      <Text>Website: www.maine-stream.com</Text>
    </Container>
  );
}
