import React, { useState, useEffect } from 'react';
import {
  Paper,
  Title,
  TextInput,
  Button,
  Stack,
  Group,
  Text,
  Alert,
  Badge,
  ActionIcon,
  Textarea,
  Box
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconAlertCircle, IconPlus, IconX } from '@tabler/icons-react';
import api from '../../services/api';

export default function SeoConfig() {
  const [config, setConfig] = useState({
    title: '',
    description: '',
    keywords: [],
    ogImage: '',
    structuredData: {},
    socialProfiles: [],
    active: true
  });
  const [newKeyword, setNewKeyword] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchConfig();
  }, []);

  const fetchConfig = async () => {
    try {
      const response = await api.get('/api/seo/config');
      setConfig(response.data);
      setError(null);
    } catch (err) {
      setError('Failed to load SEO configuration');
      console.error('Error fetching SEO config:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await api.put('/api/seo/config', config);
      notifications.show({
        title: 'Success',
        message: 'SEO configuration updated successfully',
        color: 'green'
      });
      setError(null);
    } catch (err) {
      notifications.show({
        title: 'Error',
        message: 'Failed to update SEO configuration',
        color: 'red'
      });
      console.error('Error updating SEO config:', err);
    }
  };

  const handleChange = (field) => (event) => {
    setConfig(prev => ({
      ...prev,
      [field]: event.target.value
    }));
  };

  const addKeyword = async () => {
    if (!newKeyword.trim()) return;
    
    try {
      const response = await api.post('/api/seo/keywords', { keyword: newKeyword.trim() });
      setConfig(response.data);
      setNewKeyword('');
      notifications.show({
        title: 'Success',
        message: 'Keyword added successfully',
        color: 'green'
      });
    } catch (err) {
      notifications.show({
        title: 'Error',
        message: 'Failed to add keyword',
        color: 'red'
      });
    }
  };

  const removeKeyword = async (keyword) => {
    try {
      const response = await api.delete(`/api/seo/keywords/${encodeURIComponent(keyword)}`);
      setConfig(response.data);
      notifications.show({
        title: 'Success',
        message: 'Keyword removed successfully',
        color: 'green'
      });
    } catch (err) {
      notifications.show({
        title: 'Error',
        message: 'Failed to remove keyword',
        color: 'red'
      });
    }
  };

  if (loading) {
    return <Text>Loading...</Text>;
  }

  return (
    <Paper p="md" radius="md">
      <Title order={2} mb="lg">SEO Configuration</Title>

      {error && (
        <Alert icon={<IconAlertCircle size={16} />} color="red" mb="md">
          {error}
        </Alert>
      )}

      <form onSubmit={handleSubmit}>
        <Stack spacing="md">
          <TextInput
            label="Page Title"
            required
            value={config.title}
            onChange={handleChange('title')}
            placeholder="Business Analyst & Process Consultant | Maine-Stream"
          />

          <Textarea
            label="Meta Description"
            required
            value={config.description}
            onChange={handleChange('description')}
            placeholder="Expert Business Analyst and Process Consultant offering freelance and contract services..."
            minRows={3}
          />

          <TextInput
            label="OG Image URL"
            value={config.ogImage}
            onChange={handleChange('ogImage')}
            placeholder="https://maine-stream.com/og-image.jpg"
          />

          <Box>
            <Text weight={500} size="sm" mb={8}>Keywords</Text>
            <Group spacing="xs" mb="sm">
              {config.keywords.map((keyword) => (
                <Badge 
                  key={keyword}
                  rightSection={
                    <ActionIcon
                      size="xs"
                      color="blue"
                      radius="xl"
                      variant="transparent"
                      onClick={() => removeKeyword(keyword)}
                    >
                      <IconX size={10} />
                    </ActionIcon>
                  }
                >
                  {keyword}
                </Badge>
              ))}
            </Group>
            <Group>
              <TextInput
                placeholder="Enter new keyword"
                value={newKeyword}
                onChange={(e) => setNewKeyword(e.target.value)}
                style={{ flex: 1 }}
              />
              <Button onClick={addKeyword} leftIcon={<IconPlus size={16} />}>
                Add
              </Button>
            </Group>
          </Box>

          <Button type="submit" mt="md">
            Save Changes
          </Button>
        </Stack>
      </form>
    </Paper>
  );
}
