import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

// Components
import ProtectedRoute from './components/admin/ProtectedRoute';
import AdminPanel from './components/admin/AdminPanel';

// Pages
import Landing from './pages/Landing';
import Timeline from './pages/Timeline';
import Contact from './pages/Contact';
import CaseStudies from './pages/CaseStudies';
import AdminLogin from './pages/admin/Login';
import AIAutomation from './pages/AIAutomation';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';

// Scroll restoration
function ScrollToTop() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default function AppRoutes() {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route 
          path="/timeline" 
          element={<Timeline />} 
          title="Previous Clients" 
          description="A showcase of successful collaborations and projects delivered for various clients." 
        />
        <Route path="/contact" element={<Contact />} />
        <Route path="/case-studies" element={<CaseStudies />} />
        <Route path="/ai-automation" element={<AIAutomation />} />
        <Route path="/admin/login" element={<AdminLogin />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route
          path="/admin/*"
          element={
            <ProtectedRoute>
              <AdminPanel />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </>
  );
}
