import axios from 'axios';

// Get the current hostname
const hostname = window.location.hostname;

// Determine environment based on hostname and maintenance mode
const isDevelopment = hostname === 'localhost';
const isDevSite = hostname === 'site.maine-stream.com';
const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === 'true';

// Set environment based on hostname
const environment = isDevelopment ? 'development' : isDevSite ? 'staging' : 'production';

// Determine API URL based on environment
const API_URL = isMaintenanceMode
  ? null
  : isDevelopment
    ? 'http://localhost:5000'
    : `https://${hostname}`



const instance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Add a request interceptor
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      localStorage.removeItem('token');
      window.location.href = '/admin/login';
    }
    return Promise.reject(error);
  }
);

export default instance;
