import React from 'react';
import api from '../config/api';
import { event as trackEvent } from '../utils/analytics';
import SEO from '../components/SEO';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import {
  Container,
  Title,
  Text,
  SimpleGrid,
  TextInput,
  Textarea,
  Button,
  Group,
  ActionIcon,
  Paper,
  ThemeIcon,
  Loader,
  Center,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import {
  IconBrandTwitter,
  IconBrandLinkedin,
  IconMail,
  IconMapPin,
  IconPhone,
  IconClock,
} from '@tabler/icons-react';
import { useSiteContent } from '../hooks/useSiteContent';
import classes from './Contact.module.css';

function ContactForm() {
  const { data: content, loading, error } = useSiteContent();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [opened, { open, close }] = useDisclosure(false);
  const contactData = content?.contact || {};

  const contactInfo = [
    {
      title: 'Email',
      description: contactData.email || 'contact@maine-stream.com',
      icon: IconMail,
    },
    {
      title: 'Phone',
      description: contactData.phone || '+1 (555) 123-4567',
      icon: IconPhone,
    },
    {
      title: 'Location',
      description: contactData.address ? `${contactData.address.city}, ${contactData.address.county}` : 'Leeds, UK',
      icon: IconMapPin,
    },
  ];

  const social = [
    { 
      Icon: IconBrandTwitter, 
      link: contactData.social?.twitter || '#'
    },
    { 
      Icon: IconBrandLinkedin, 
      link: contactData.social?.linkedin || '#'
    },
    { 
      Icon: IconMail, 
      link: `mailto:${contactData.email || 'contact@maine-stream.com'}`
    },
  ];

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (!executeRecaptcha) {
      notifications.show({
        title: 'Error',
        message: 'reCAPTCHA not loaded. Please refresh the page and try again.',
        color: 'red',
        autoClose: 5000
      });
      return;
    }
    
    try {
      // Execute reCAPTCHA with action
      const token = await executeRecaptcha('contact_form');
      
      // Get form data directly from the form elements instead of using FormData constructor
      const form = event.target;
      const data = {
        name: form.name.value,
        email: form.email.value,
        message: form.message.value,
        recaptchaToken: token
      };
      const response = await api.post('/api/email/send-contact', data);
      
      // Track successful form submission
      trackEvent({
        action: 'submit_form',
        category: 'Contact',
        label: 'Contact Form Submission',
        value: 1
      });

      notifications.show({
        title: 'Success',
        message: response.data.message || 'Thank you for your message. I will get back to you soon!',
        color: 'green',
        autoClose: 5000
      });
      // Use the form variable already declared above
      if (form && typeof form.reset === 'function') {
        form.reset();
      }
    } catch (error) {
      console.error('Failed to send message:', error);
      
      // Track failed form submission
      trackEvent({
        action: 'form_error',
        category: 'Contact',
        label: `Contact Form Error: ${error.message}`,
        value: 0
      });

      console.error({
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
        headers: error.response?.headers,
        config: error.config
      });
      notifications.show({
        title: 'Error',
        message: error.response?.data?.message || 'Failed to send message. Please try again later.',
        color: 'red',
        autoClose: 5000
      });
    }
  };

  if (loading) {
    return (
      <Center style={{ height: '100vh' }}>
        <Loader size="lg" />
      </Center>
    );
  }

  if (error) {
    return (
      <Center style={{ height: '100vh' }}>
        <Text>Error loading contact information</Text>
      </Center>
    );
  }

  const icons = social.map((item, index) => (
    <ActionIcon
      key={index}
      size={28}
      className={classes.social}
      variant="transparent"
      component="a"
      href={item.link}
      target="_blank"
    >
      <item.Icon size={22} stroke={1.5} />
    </ActionIcon>
  ));

  return (
    <>
      <SimpleGrid cols={{ base: 1, sm: 2 }} spacing={50}>
        <div className={classes.content}>
          <Title className={classes.title}>Get in Touch</Title>
          <Text className={classes.description} mt="sm" mb={30}>
            Leave a message and I'll get back to you as soon as possible. Let's discuss how I can help transform your business.
          </Text>

          <SimpleGrid cols={1} spacing="xl" mt={50}>
            {contactInfo.map((item, index) => (
              <Group key={index} wrap="nowrap">
                <ThemeIcon
                  size={40}
                  radius="md"
                  variant="filled"
                  className={classes.itemIcon}
                >
                  <item.icon size={24} stroke={1.5} />
                </ThemeIcon>

                <div>
                  <Text size="sm" fw={700} className={classes.itemTitle}>
                    {item.title}
                  </Text>
                  <Text className={classes.itemDescription}>{item.description}</Text>
                </div>
              </Group>
            ))}
          </SimpleGrid>

          <Group mt="xl">{icons}</Group>
        </div>

        <Paper shadow="md" radius="lg" className={classes.form} p={30}>
          <form
            className={classes.form}
            onSubmit={handleSubmit}
          >
            <TextInput
              label="Name"
              name="name"
              placeholder="Your name"
              required
              classNames={{ input: classes.input, label: classes.inputLabel }}
            />
            <TextInput
              label="Email"
              name="email"
              placeholder="Your email"
              required
              mt="md"
              classNames={{ input: classes.input, label: classes.inputLabel }}
            />
            <Textarea
              required
              label="Message"
              name="message"
              placeholder="Your message"
              minRows={4}
              mt="md"
              classNames={{ input: classes.input, label: classes.inputLabel }}
            />

            <Group justify="flex-end" mt="md">
              <Button 
                type="submit" 
                className={classes.control}
              >
                Send message
              </Button>
            </Group>
          </form>
        </Paper>
      </SimpleGrid>
    </>
  );
}

export default function Contact() {
  return (
    <Container size="lg" className={classes.wrapper}>
      <SEO 
        title="Contact | Business Analyst & Process Consultant | Maine-Stream"
        description="Get in touch with Maine-Stream for expert business analysis and process consulting services. Contact us to discuss your project needs."
        keywords="contact business analyst, hire process consultant, business analysis services, process optimization consulting"
      />
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        scriptProps={{
          async: true,
          defer: true,
          appendTo: 'head',
        }}
      >
        <ContactForm />
      </GoogleReCaptchaProvider>
    </Container>
  );
}
