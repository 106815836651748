import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

const SEO = ({ 
  title = 'Business Analyst & Process Consultant | Maine-Stream',
  description = 'Expert Business Analyst and Process Consultant offering freelance and contract services. Specializing in process optimization, requirements analysis, and digital transformation.',
  image = '/og-image.jpg',
  article = false,
  keywords = 'business analyst, freelance business analyst, process consultant, contractor, process analyst, requirements analysis, digital transformation, business process optimization',
  publishedTime,
  modifiedTime,
  author = 'Maine Stream',
  category,
  schemaType = 'LocalBusiness',
  locale = 'en_US'
}) => {
  const { pathname } = useLocation();
  const siteUrl = 'https://maine-stream.com';
  const url = `${siteUrl}${pathname}`;
  
  // Generate structured data based on page type
  const generateStructuredData = () => {
    const baseData = {
      '@context': 'https://schema.org',
      '@type': schemaType,
      name: title.split('|')[0].trim(),
      description,
      url,
      image: `${siteUrl}${image}`,
    };
    
    if (schemaType === 'LocalBusiness') {
      return {
        ...baseData,
        address: {
          '@type': 'PostalAddress',
          addressLocality: 'Leeds',
          addressRegion: 'West Yorkshire',
          addressCountry: 'UK'
        },
        priceRange: '££',
        telephone: '+44-000-000-0000', // Replace with actual phone
        email: 'contact@maine-stream.com'
      };
    }
    
    if (schemaType === 'Article' && article) {
      return {
        ...baseData,
        '@type': 'Article',
        headline: title.split('|')[0].trim(),
        author: {
          '@type': 'Person',
          name: author
        },
        ...(publishedTime && { datePublished: publishedTime }),
        ...(modifiedTime && { dateModified: modifiedTime }),
        ...(category && { articleSection: category }),
        publisher: {
          '@type': 'Organization',
          name: 'Maine Stream',
          logo: {
            '@type': 'ImageObject',
            url: `${siteUrl}/logo.png`
          }
        }
      };
    }
    
    // Default to WebPage schema
    return baseData;
  };
  
  const structuredData = generateStructuredData();
  
  return (
    <Helmet>
      {/* Basic meta tags */}
      <html lang="en" />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <link rel="canonical" href={url} />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      <meta name="theme-color" content="#ffffff" />
      <meta name="robots" content="index, follow" />
      <meta name="language" content="English" />
      <meta name="revisit-after" content="7 days" />
      <meta name="author" content={author} />
      
      {/* Open Graph / Facebook */}
      <meta property="og:locale" content={locale} />
      <meta property="og:site_name" content="Maine Stream" />
      <meta property="og:type" content={article ? 'article' : 'website'} />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={`${siteUrl}${image}`} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      
      {/* Article specific OG tags */}
      {article && publishedTime && <meta property="article:published_time" content={publishedTime} />}
      {article && modifiedTime && <meta property="article:modified_time" content={modifiedTime} />}
      {article && category && <meta property="article:section" content={category} />}
      {article && <meta property="article:author" content={author} />}
      
      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={url} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={`${siteUrl}${image}`} />
      <meta name="twitter:creator" content="@mainestream" />
      
      {/* Structured Data / JSON-LD */}
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
    </Helmet>
  );
};

export default SEO;
